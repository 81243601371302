.contact-page {
  background-color: white;
}

.contact-page a:not(.btn-danger),
h4 a {
  color: #333;
}

h4 a {
  text-decoration: underline;
}

.contact-data .contact-social-buttons {
}

.contact-data h4 {
  font-size: smaller;
}

.image-container {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  max-width: 500px;
  margin: auto;
}

.whatsapp-qr-code {
  background-image: url(https://cdn.lawrencemcdaniel.com/wp-content/uploads/2020/10/28130958/whatsapp-qr-code.png);
}

.venmo-qr-code {
  background-image: url(https://cdn.lawrencemcdaniel.com/wp-content/uploads/2022/07/27232736/qr-code-venmo.jpg);
}

.linkedin-qr-code {
  background-image: url(https://cdn.lawrencemcdaniel.com/wp-content/uploads/2021/09/30174919/linkedin-qr-code.png);
}

.linkedin-logo {
  background-image: url(https://cdn.lawrencemcdaniel.com/wp-content/uploads/2021/09/30180629/linkedin-logo.png);
  max-width: 125px;
}

.whatsapp-logo {
  background-image: url(https://cdn.lawrencemcdaniel.com/wp-content/uploads/2021/09/30182902/whatsapp-logo.png);
  max-width: 125px;
}

.square {
  width: 100%;
  height: 0;
  padding-top: 100%;
}
