html,
body {
  height: 100%;
}

body {
  max-width: 1200px;
  background-color: #f1f1f1;
  margin-left: auto;
  margin-right: auto;
  /* padding-top: 59px; */
}

.online {
}
.offline {
  filter: grayscale(100%);
}

/* Page Insights recommendation for font awesome */
@font-face {
  font-display: swap;
}

.site-page {
  padding-bottom: 25px;
}

.navbar-dark .navbar-nav .nav-link {
  font-family: 'Courier New', Courier, monospace;
  font-size: large;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

p a:not(.btn-danger) {
  color: #333;
  text-decoration: underline;
  font-style: italic;
}

.dropdown-item a {
  color: #555;
}

/*-------------- Responsive Styling -----------------------

xs = Extra small <576px. Max container width None (auto)
sm = Small ≥576px. Max container width 540px.
md = Medium ≥768px. Max container width 720px.
lg = Large ≥992px. Max container width 960px.
xl = Extra large ≥1200px. Max container width 1140px.

*/

/********** Extra large devices only **********/
@media (min-width: 1200px) {
}

/********** Large devices only **********/
@media (min-width: 992px) and (max-width: 1199px) {
}

/********** Medium devices only **********/
@media (min-width: 768px) and (max-width: 991px) {
}

/********** Small devices only **********/
@media (min-width: 576) and (max-width: 767px) {
}

/********** Extra small devices only **********/
@media (min-width: 480px) and (max-width: 575px) {
}
/********** Super extra small devices Only :-) (e.g., iPhone 4) **********/
@media (max-width: 479px) {
}

/*  ------------ MCDANIEL ------------------ */
@media (max-width: 991px) {
  .hide-medium {
    display: none;
    width: 0 !important;
    height: 0 !important;
  }
}

@media (max-width: 767px) {
  .hide-small {
    display: none;
    width: 0 !important;
    height: 0 !important;
  }
}
