.image-tagging-page {
  background-color: white;
}

.drag-drop-zone {
  border: 1px dashed darkgreen;
  border-radius: 10px;
  background-color: #f1f1f1;
  height: 375px;
  transition: all 250ms ease-in-out;
}
.drag-drop-zone:hover {
  border-color: #333;
  border-style: solid;
  background-color: #e1e1e1;
}

.meta-tags {
  border: 2px solid #333;
  background-color: #1e1e1e;
  color: lightgrey;
}

.meta-tags-column {
  height: 500px;
}

.meta-tags-column-objects {
  border-right: 0.5px solid darkgray;
}
.meta-tags-column-attributes {
}

.meta-tags-objects {
  border-right: 0.5px solid darkgray;
  border-bottom: 0.5px solid darkgray;
}
.meta-tags-attributes {
  border-bottom: 0.5px solid darkgray;
}

.meta-header {
}
