.react-page {
  background-color: white;
}

.react-page-leader {
  font-weight: bold;
}

.aws-diagram img {
  width: 100%;
}
