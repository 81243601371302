.bio-page {
  background-color: white;
}

.bio-resume-button {
  color: #000000;
  background-color: #ffffff;
  border: 2px solid #474d5d;
}
.bio-resume-button a {
  color: #000000;
}
