.app-navbar {
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: black;
  padding-left: 15px;
}

.nav-link:focus,
.nav-link:hover,
.nav-item,
.dropdown-item,
.dropdown-menu {
  background-color: black !important;
}

.dropdown-menu {
  border-left: 0.5px solid #222;
  border-bottom: 0.5px solid #222;
  border-right: 0.5px solid #222;
}
