.footer {
  background-color: black;
  color: white;
  margin: 0px auto;
  padding: 20px 0 0 0;
  font-family: 'Courier New', Courier, monospace;
}
.footer {
  font-size: x-small;
}

.footer a {
  color: white;
}

.edx-links {
  color: white !important;
  font-style: normal !important;
}

/*-------------- Responsive Styling -----------------------*/

/********** Large devices only **********/
@media (min-width: 1200px) {
}

/********** Medium devices only **********/
@media (min-width: 992px) and (max-width: 1199px) {
}

/********** Small devices only **********/
@media (min-width: 768px) and (max-width: 991px) {
}

/********** Extra small devices only **********/
@media (max-width: 767px) {
}
/********** Super extra small devices Only :-) (e.g., iPhone 4) **********/
@media (max-width: 479px) {
}
